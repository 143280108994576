import React from "react"
import {Link} from "@reach/router";

const Feder = () => {

    return (
        <div id="ocultar" className="card card--eu d-none">
            <span className="close--eu"><i
                className="fe fe-x"/></span>
            <Link to="es/subvenciones"><img src={require("../../img/ue.png").default} className="img--eu" alt="eu"/></Link>
        </div>
    )
};

export default Feder
