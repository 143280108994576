import React from "react"
import {StaticImage} from "gatsby-plugin-image";

const ModalDexga = () => {

    return (
        <div className="modal" id="dexgaModal" tabIndex="-1" role="dialog" aria-labelledby="dexgaModalLabel"
             aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <StaticImage src={"../../images/dxtecalis.jpg"} className="img-fluid border-radius-top" alt=""/>
                    <div className="modal-body">
                        <div className="cookie-text spacing ml-4 mr-3 mt-2">
                            <div className="row">
                                <div align="center" className="col-xl-4">
                                    <StaticImage src={"../../images/dexga.png"} width={100} alt="Dexga"/>
                                </div>
                                <div align="center" className="col-xl-4">es ahora</div>
                                <div align="center" className="col-xl-4">
                                    <StaticImage src={"../../img/brand.png"} width={100} alt="Tecalis"/>
                                </div>
                            </div>
                        </div>
                        <br/>
                        <div className="row mt-4 mb-4">
                            <div className="col-xl-8 offset-xl-2">
                                <br/>
                                <a href="/#" className="btn btn-primary btn-block spacing"
                                   id="dexgaModalCloseButton"
                                   data-dismiss="modal">Continuar</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalDexga
