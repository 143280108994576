import React from "react"
import {graphql, Link, useStaticQuery} from "gatsby";

const CookieLand = ({data, pageContext}) => {

    const langSlug = '/' + pageContext.locale.path.default;
    const menuPages = useStaticQuery(graphql`
        query {
            markdownRemark(
                frontmatter: {
                    name: {
                        eq: "menu-pages"
                    }
                }) {
                frontmatter {
                    en{
                        privacy{
                            url
                            name
                        }
                   }
                   es{
                        privacy{
                            url
                            name
                        }
                   }
                }
            }
        }
    `);

    return (
        <div className="modal" id="cookieModal" tabIndex="-2" role="dialog" aria-labelledby="exampleModalLabel"
             aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-body">
                        <div align="left" className="cookie-text spacing ml-4 mr-3 mt-2 font-weight-bold">
                            {data.frontmatter.cookiesStart}&nbsp;
                            <Link to={menuPages.markdownRemark.frontmatter[pageContext.lang].privacy.url}
                                  className="color-tecalis" id="cookieModalPrivacyButton">{data.frontmatter.cookiesEnd}.</Link>

                        </div>
                        <br/>

                        <div className="row mt-3 mb-4">
                            <div className="col-xl-8 offset-xl-2">
                                <a href="/#!" className="btn btn-primary btn-block spacing" id="cookieModalCloseButton"
                                      data-dismiss="modal">{data.frontmatter.linkText}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CookieLand
